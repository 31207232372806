@tailwind base;
@tailwind components;
@tailwind utilities;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "flowbite";
@import "~react-image-gallery/styles/css/image-gallery.css";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f7f7f7;
}

li {
  list-style: none;
}
